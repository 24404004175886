import { Component, ChangeDetectorRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../../../app/main/services/http.service';
import { APPCONFIG } from '../../../app/main/config';
import { COMMON_UTIL } from '../../../app/main/common_util';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'view-pod-dialog',
    templateUrl: './view-pod-dialog.component.html?v=${APPCONFIG.cacheVersion}',
    styleUrls: ['./view-pod-dialog.component.scss?v=${APPCONFIG.cacheVersion}'],
    providers: [DatePipe]
})
export class ViewPodDialogComponent implements OnInit{
  @ViewChild('chooseFileInput') chooseFileInputVar: ElementRef;
  confirmDialogRefSession: MatDialogRef<sessionConfirmDialogComponent>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  public title: string;
  public podDetailsData = {jobPkId:0, fileType:'', approvalPending:false, approvedBy:'', carrier:false, approved:false, approvedAt:'', 
          autoApproveUserType:'', esigned:false, notes:'', plannedDeliveryDate:'', actualDeliveryDate:''};
  public base64Pod:any;
  approveBtnText='Approve';
  deliveryPodDetails=[];
  podDate=new Date();
  selectedPodDate='';
  minPodDate = new Date();
  maxPodDate = new Date();
  podBlobUrl:any;
  result;

  /**
   * Constructor
   *
   * @param {MatDialogRef<ViewPodDialogComponent>} dialogRef
   */
  //onClose: {};
  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService, private cdr:ChangeDetectorRef, private http: HttpClient, 
      private http1: HttpService, private domSanitizer: DomSanitizer, 
      public dialogRef: MatDialogRef<ViewPodDialogComponent>,
      private datePipe: DatePipe,
      private dateAdapter: DateAdapter<Date>
  ) {
      dialogRef.disableClose = true;
      this.dateAdapter.setLocale('en-GB'); // Set date to dd/MM/yyyy
  }
  ngOnInit(): void {
    this.createPodBlobUrl();
    this.initPodDates();
  }
  close() {
      this.dialogRef.close(this.podDetailsData)
  }

  readURL(event: Event): void {
    var fileType='';
    
    this.spinner.show();
    if (event.type == 'click') {
        this.spinner.hide();
        return;
    }
    if ((<HTMLInputElement>event.target).files && (<HTMLInputElement>event.target).files[0]) {
        const file = (<HTMLInputElement>event.target).files[0];
        if(file.type=='application/pdf') { //PDF
            fileType='pdf';
        }else {
            fileType='jpg';
        }
        if (typeof (FileReader) !== 'undefined') {
            let reader = new FileReader();
            reader.onload = e => {
                this.updatePodDetails(reader.result, fileType);
                this.spinner.hide();
                this.cdr.detectChanges();
            };
            reader.readAsDataURL(file);
        }
    }
  }
  updatePodDetails(fileSrc, fileType) {
    this.deliveryPodDetails.push({"podSrc": fileSrc, "podFileType": fileType, "podSrcType": 'uploaded'});
    this.updateApproveBtnText();
  }
  resetPod() {
    this.deliveryPodDetails=[];
    this.updateApproveBtnText();
    this.chooseFileInputVar.nativeElement.value = "";
  }
  initApprovePod() {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    var title = 'Are you sure you want to approve the POD for this job?';
    if(this.deliveryPodDetails.length>0) {
      title = 'Are you sure you want to replace and approve the POD for this job?';
    }
    this.confirmDialogRef.componentInstance.confirmMessage = title;
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.approvePod();
      }
    });
  }
  approvePod() {
    this.spinner.show();
    var podDetails=this.getCleanedPodDetails();
      let body = JSON.stringify({'jobPkId':this.podDetailsData.jobPkId,'deliveryPodDetails':podDetails,'podDate':this.selectedPodDate});
      let token = JSON.parse(localStorage.getItem('Token'));
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'idToken': token }) };

      this.http.post(APPCONFIG.appUrl + '/epw/approveSecondaryFreightJobPod', body, httpOptions)
        .subscribe(data => {
          this.result = data;
          if (this.result.httpStatusCode == 200 || this.result.httpStatusCode == 201) {
            this.podDetailsData.approvalPending=false;
            this.podDetailsData.approvedBy=this.getSessionUserName(true);
            this.podDetailsData.approved=true;

            this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
            this.confirmDialogRefSession.afterClosed().subscribe(result => {
                this.close(); // also close the POD view Dialog
            });
            
            if(podDetails && podDetails.length>0) {
              this.podDetailsData.fileType='pdf';
              this.base64Pod = this.result.data;
              this.createPodBlobUrl();
              this.confirmDialogRefSession.componentInstance.confirmMessage = "Job POD replaced and approved";
            }else {
              this.confirmDialogRefSession.componentInstance.confirmMessage = "Job POD approved";
            }

            this.cdr.detectChanges();
          }
          this.spinner.hide();
        },
          err => {
            this.spinner.hide();
            let title = "Failed to update."
            this.http1.errorHandler(err, title);
          });
  }
  updateApproveBtnText() {
    if(this.deliveryPodDetails.length>0) {
      this.approveBtnText='Replace & Approve';
    }else {
      this.approveBtnText='Approve';
    }
  }
  getCleanedPodDetails() {
    let arr=[];
    this.deliveryPodDetails.forEach(podDetail => {
      podDetail.podSrc = COMMON_UTIL.cleanMetadataFromBase64(podDetail.podSrc, podDetail.podFileType);
      arr.push({"podSrc": podDetail.podSrc, "podFileType": podDetail.podFileType, "podSrcType": podDetail.podSrcType});
    })
    return arr;
  }

  createPodBlobUrl() {
    var blob;
    var buffer = this.base64ToArrayBuffer(this.base64Pod);
    if(this.podDetailsData.fileType=='pdf') {
      blob = new Blob([buffer], { type: 'application/pdf'});
    }else {
      blob = new Blob([buffer], { type: 'image/jpg'});
    }
    this.podBlobUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
  }
  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64.replace(/\s/g, ''));
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  
  getSessionUserName(includeEmail) {
    let user = JSON.parse(localStorage.getItem('user'));
    var name = user.firstName + " " + user.surName;
    if(includeEmail) {
      name = name + ' ('+user.userId+')';
    }
    return name;
  }

  initPodDates() {
    this.podDate = new Date(this.podDetailsData.actualDeliveryDate);
    this.minPodDate = new Date(this.podDetailsData.plannedDeliveryDate);
    this.minPodDate.setDate(this.minPodDate.getDate()-7); // Allow dates 7 days early delivery

    this.selectedPodDate = this.datePipe.transform(this.podDate, 'yyyy-MM-dd');
  }
  updatePodSelectedDate(event) {
      this.selectedPodDate = this.datePipe.transform(event.value, 'yyyy-MM-dd');
  }

}
