import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { APPCONFIG } from '../../../app/main/config';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'select-job-dialog',
    templateUrl: './select-job-dialog.component.html',
    styleUrls: ['./select-job-dialog.component.scss'],
    providers: []
})
export class SelectJobDialogComponent {
  public selectedJobPkId='';
  public config = {title:'', msg:'', jobs:[]};
  public returnData = {selected:false, jobPkId:''};


  /**
   * Constructor
   * @param {MatDialogRef<SelectJobDialogComponent>} dialogRef
   */

  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService, 
      public dialogRef: MatDialogRef<SelectJobDialogComponent>
  ) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    //
  }
  closeDialog() {
      this.dialogRef.close(this.returnData);
  }
  submit() {
    if(this.selectedJobPkId) {
      this.returnData.jobPkId=this.selectedJobPkId;
      this.returnData.selected=true;
    }
    this.closeDialog();
  }

}
