import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule, MatDialogModule, MatIconModule, MatDatepickerModule, MatFormFieldModule, MatSelectModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SelectJobDialogComponent } from './select-job-dialog.component';

@NgModule({
    declarations: [
        SelectJobDialogComponent
    ],
    imports: [
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        CommonModule
    ],
    entryComponents: [
        SelectJobDialogComponent
    ],
})
export class SelectJobDialogModule
{
}
