import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule, MatIconModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatCardModule, MatGridListModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ViewDeliveryDocumentDialogComponent } from './view-delivery-document-dialog.component';

@NgModule({
    declarations: [
        ViewDeliveryDocumentDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        CommonModule,
        FormsModule,
        MatCardModule,
        MatGridListModule,
        FlexLayoutModule
    ],
    entryComponents: [
        ViewDeliveryDocumentDialogComponent
    ],
})
export class ViewDeliveryDocumentDialogModule
{
}
