export class COMMON_UTIL {
    static getFormattedLocalDate(dateStringInUtc) {
        if(dateStringInUtc) {
          try {
            var dateObj = new Date(dateStringInUtc+' UTC');
            return dateObj.toLocaleDateString("en-GB",{year:'numeric',month:'long',day:'numeric', hour:'numeric',minute:'numeric',timeZoneName:'short'});
          }catch(err) {
            console.error(err);
          }
        }
        return '';
    }

    static cleanMetadataFromBase64(fileSrc, fileType) {
      if(fileType=='pdf') {
        return fileSrc.replace(/^data:application\/\w+;base64,/, "");
      }
      else if(fileType=='jpg') {
          return fileSrc.replace(/^data:image\/\w+;base64,/, "");
      }
      return '';
    }


    static getAuditMessageForDeliveryDocuments(documentData) {
      var dataArray = [];
      makeAuditMessage(dataArray,documentData.adminUploadAtUtc,'Admin'+(documentData.adminUploadBy ? ' <strong>'+documentData.adminUploadBy+'</strong>' : ''));
      makeAuditMessage(dataArray,documentData.apiUploadAtUtc,'Api');
      makeAuditMessage(dataArray,documentData.carrierUploadAtUtc,'Carrier');
  
      if(dataArray.length==0) {
        return '';
      }
      dataArray.sort(function(a, b) { return b.date - a.date });
  
      var msg = '';
      dataArray.forEach(obj => {
        msg = msg+obj.msg+'<br>';
      })
      msg = msg.replace(/<br>([^<br>]*)$/, '$1'); //Remove unnecessary <br> in the end
      console.log(msg);
      return msg;
    }
}

function makeAuditMessage(dataArr, utcDate, uploadType) {
  if(utcDate) {
    dataArr.push({date:new Date(utcDate+' UTC'), msg:'<span class="material-icons check-icon">check</span>&nbsp;'+uploadType+' uploaded document on '+COMMON_UTIL.getFormattedLocalDate(utcDate)});
  }
}
