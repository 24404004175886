import { Component, ChangeDetectorRef, ViewChild, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from '../../../app/main/services/http.service';
import { APPCONFIG } from '../../../app/main/config';
import { COMMON_UTIL } from '../../../app/main/common_util';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { sessionConfirmDialogComponent } from '@fuse/components/confirm-dialog-session/confirm-dialog-session.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { ChooseFileDialogComponent } from '@fuse/components/choose-file-dialog/choose-file-dialog.component';

@Component({
    selector: 'view-delivery-document-dialog',
    templateUrl: './view-delivery-document-dialog.component.html?v=${APPCONFIG.cacheVersion}',
    styleUrls: ['./view-delivery-document-dialog.component.scss?v=${APPCONFIG.cacheVersion}']
})
export class ViewDeliveryDocumentDialogComponent implements OnInit{
  @ViewChild('chooseFileInput') chooseFileInputVar: ElementRef;
  confirmDialogRefSession: MatDialogRef<sessionConfirmDialogComponent>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  chooseFileDialog: MatDialogRef<ChooseFileDialogComponent>;
  public title: string;
  public documentDetailsData = {jobPkId:0, fileType:'', allDocumentTypes:[], selectedDocumentTypeId:0, deadlineLocalTime:'',uploadRestricted:false,auditMessage:''};
  public base64Pod:any;
  documentBlobUrl:any;
  result;

  /**
   * Constructor
   *
   * @param {MatDialogRef<ViewDeliveryDocumentDialogComponent>} dialogRef
   */
  //onClose: {};
  constructor(public dialog: MatDialog, private spinner: NgxSpinnerService, private cdr:ChangeDetectorRef, private http: HttpClient, 
      private http1: HttpService, private domSanitizer: DomSanitizer, 
      public dialogRef: MatDialogRef<ViewDeliveryDocumentDialogComponent>,
      private dateAdapter: DateAdapter<Date>
  ) {
      dialogRef.disableClose = true;
      this.dateAdapter.setLocale('en-GB'); // Set date to dd/MM/yyyy
  }
  ngOnInit(): void {
    this.createDocumentBlobUrl();
  }
  close() {
    this.dialogRef.close({jobPkId:this.documentDetailsData.jobPkId, count:this.findAvailableDocCount()});
  }

  createDocumentBlobUrl() {
    var buffer = this.base64ToArrayBuffer(this.base64Pod);
    var mimeType = (this.documentDetailsData.fileType=='pdf') ? 'application/pdf' : 'image/jpg';
    var blob = new Blob([buffer], { type: mimeType});
    this.documentBlobUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
  }

  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64.replace(/\s/g, ''));
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  getSessionUserName(includeEmail) {
    let user = JSON.parse(localStorage.getItem('user'));
    var name = user.firstName + " " + user.surName;
    if(includeEmail) {
      name = name + ' ('+user.userId+')';
    }
    return name;
  }

  view(documentId) {
    this.spinner.show();
    const httpOptions = { headers: new HttpHeaders({'Content-Type':'application/json', 'idToken':JSON.parse(localStorage.getItem('Token'))}) };

    this.http.get(APPCONFIG.appUrl + '/deliveryDocument/'+this.documentDetailsData.jobPkId+'/'+documentId, httpOptions)
      .subscribe(data => {
        this.result = data;
        if (this.result.httpStatusCode == 200 || this.result.httpStatusCode == 201) {
          this.updateDocTypeSelection(documentId, false);
          this.documentDetailsData.auditMessage=COMMON_UTIL.getAuditMessageForDeliveryDocuments(this.result.data);
          this.updateDocumentView(this.result.data.fileType, this.result.data.documentSrc);
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        let title = "Failed to get the delivery document"
        this.http1.errorHandler(err, title);
      });
  }

  initDeleteDeliveryDocument(documentId, documentType, isSelected) {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure to delete the '+documentType+' document';
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteDeliveryDocument(documentId,isSelected);
      }
    });
  }

  deleteDeliveryDocument(documentId,isSelected) {
    this.spinner.show();
    const httpOptions = { headers: new HttpHeaders({'Content-Type':'application/json', 'idToken':JSON.parse(localStorage.getItem('Token'))}) };

    this.http.delete(APPCONFIG.appUrl + '/deliveryDocument/'+this.documentDetailsData.jobPkId+'/'+documentId, httpOptions)
      .subscribe(data => {
        this.result = data;
        if (this.result.httpStatusCode == 200 || this.result.httpStatusCode == 201) {
          this.confirmDialogRefSession = this.dialog.open(sessionConfirmDialogComponent);
          this.confirmDialogRefSession.componentInstance.confirmMessage = "Delivery Document deleted";

          this.updateDocTypeSelection(documentId, true);
          if(isSelected) {
            this.updateDocumentView('pdf', '');
          }else {
            this.cdr.detectChanges();
          }
        }
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        let title = "Failed to delete the delivery document"
        this.http1.errorHandler(err, title);
      });
  }

  updateDocTypeSelection(documentId, isDeleted) {
    console.log("Type to be updated: "+documentId);
    this.documentDetailsData.allDocumentTypes.forEach(documentType => {
      if(documentType.documentId==documentId) {
        documentType.available=!isDeleted;
      }
      if(!isDeleted) {
        documentType.selected=(documentType.documentId==documentId);
      }
    })
  }

  updateDocumentView(fileType, fileSrc) {
    this.documentDetailsData.fileType=fileType;
    this.base64Pod = fileSrc;
    this.createDocumentBlobUrl();
    this.cdr.detectChanges();
  }

  initUploadDeliveryDocument(documentId, documentType, isReplaced) {
    this.chooseFileDialog = this.dialog.open(ChooseFileDialogComponent,{panelClass: 'choose-file-dialog-panel'});
    this.chooseFileDialog.componentInstance.title = (isReplaced?'Replace':'Add')+' Delivery Document';
    this.chooseFileDialog.componentInstance.config.msg = (isReplaced?'Replace':'Add')+' the '+documentType+' delivery document?';
    this.chooseFileDialog.componentInstance.config.uploadUrl = APPCONFIG.appUrl + '/deliveryDocument/replace/'+this.documentDetailsData.jobPkId+'/'+documentId;
    this.chooseFileDialog.componentInstance.config.closeAfterUploadSuccess = true;
    this.chooseFileDialog.componentInstance.config.uploadFailureMsg = 'Failed to '+(isReplaced?'replace ':'add ')+' the '+documentType+' delivery document';

    this.chooseFileDialog.afterClosed().subscribe(result => {
      if(result.upload) {
        this.updateDocTypeSelection(documentId, false);
        this.updateDocumentView(result.fileType,result.fileSrc);
      }
    });
  }

  findAvailableDocCount() {
    var count=0;
    this.documentDetailsData.allDocumentTypes.forEach(documentType => {
      if(documentType.available) {
        count++;
      }
    })
    return count;
  }

}
